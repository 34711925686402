* {
  font-family: 'Rubik', sans-serif;
}

.App {
  text-align: center;
  width: 100%;
  margin: 0rem 3rem 3rem 0rem;
  overflow-y: hidden;
}

h1{
  margin: 0;
  font-size: 30px;
}

h2{
  margin: 0;
  font-size: 28px;
}

h3{
  margin: 0;
  font-size: 26px;
}

h4{
  margin: 0;
  font-size: 24px;
}

h5{
  margin: 0;
  font-size: 22px;
}

h6{
  margin: 0;
  font-size: 20px;
}

p{
  margin: 0;
  font-size: 16px;
}



